import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import messengerLogo from "../images/messenger-icon.svg";
import instaLogo from "../images/instagram-icon.svg";
import SEO from "../components/seo";

export default function Contact() {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="If you'd like to find out more or request your own custom artwork please contact us"
      />
      <main className="w-full flex-grow max-w-screen-xl mx-auto py-4 px-4-safe-area sm:px-6-safe-area" role="main">
        <h1 className="text-3xl pt-1 pb-2 md:text-4xl md:pb-4 md:pt-5 lg:pt-6">Contact</h1>
        <p className="text-md mb-3">
          If you'd like to find out more or request your own custom artwork please contact us on&nbsp;
          <a href="https://m.me/273354659926671" onClick={() => window.fathom.trackGoal('55QWWMQP', 0)} target="_blank" rel="noreferrer" className="underline whitespace-nowrap">
            <img alt="Messenger" className="w-6 inline pr-1" src={messengerLogo} />Messenger
          </a>
          &nbsp;or&nbsp;
          <a href="https://www.instagram.com/colourstormart/" onClick={() => window.fathom.trackGoal('G7YWQFSH', 0)} target="_blank" rel="noreferrer" className="underline whitespace-nowrap">
            <img alt="Instagram" className="w-5 inline pr-1" src={instaLogo} />Instagram
          </a>.
        </p>
        <p className="text-md">You can also complete the form below and we'll get back to you ASAP</p>

        <div className="mt-6 max-w-3xl">
          <ContactForm/>
        </div>
      </main>
    </Layout>
  )
}
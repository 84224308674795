import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import isEmail from 'validator/es/lib/isEmail';
import 'whatwg-fetch';
 
export default function ContactForm() {

  let submitted = '';

  const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      var error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validate={values => {
            const errors = {};

            if(values.name === '') {
              errors.name = 'Please enter your name';
            }

            if(!isEmail(values.email)) {
              errors.email = 'Please enter a valid email';
            }

            if(values.message === '') {
              errors.message = 'Please enter a message';
            }

          return errors;
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact-form", ...values })
          })
          .then(checkStatus)
          .then(() => {
            submitted = 'success';
            resetForm();
          }).catch(() => {
            submitted = 'error';
          }).finally(() => setSubmitting(false))
        }}
      >
        {({ errors, isSubmitting }) => (
          <div>
            <div className={`${submitted === 'success' ? 'flex' : 'hidden'} flex-col text-center py-4 col-span-2`}>
              <p>Thank you <span role="img" aria-label="rainbow">🌈</span>  We'll get back to you ASAP</p>
            </div>
            <div className={`${submitted === 'error' ? 'flex' : 'hidden'} flex-col text-center py-4 col-span-2`}>
              <p className="text-red-500">Sorry, something went wrong <span role="img" aria-label="sad face">😢</span><br/>Please try again</p>
            </div>

            <Form className={`${submitted === 'success' ? 'hidden' : 'block'} grid grid-cols-2 gap-4`} name="contact-form" data-netlify={true} noValidate>
              <div className="col-span-2 md:col-span-1">
                <label htmlFor="name">Name</label>
                <Field id="name" className={`${errors.name ? 'border-red-500' : 'border-white'} w-full h-9 rounded border-2 bg-black text-white`} type="text" name="name" />
                <ErrorMessage className="text-sm py-1 text-red-500" name="name" component="div" />
              </div>
              <div className="col-span-2 md:col-span-1">
                <label htmlFor="email">Email</label>
                <Field id="email" className={`${errors.email ? 'border-red-500' : 'border-white'} w-full h-9 rounded border-2 bg-black text-white`} type="email" name="email" />
                <ErrorMessage className="text-sm py-1 text-red-500" name="email" component="div" />
              </div>
              <div className="col-span-2">
                <label htmlFor="message">Message</label>
                <Field id="message" className={`${errors.message ? 'border-red-500' : 'border-white'} w-full h-40 rounded border-2 bg-black text-white`} component="textarea" name="message" />
                <ErrorMessage className="text-sm py-1 text-red-500" name="message" component="div" />
              </div>
              <div className="col-span-2">
                <button className="w-full rounded bg-white text-black px-2.5 py-1.5 mt-2" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Sending...' : 'Send'}
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  )
};
